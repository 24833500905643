import React, { PureComponent } from 'react';
import swal from '@sweetalert/with-react';
import { debounce } from 'throttle-debounce';
import OffWallStreamUnavailable from '../../common/Messages/OffWallStreamUnavailable';
import EnterMeeting from '../../common/Messages/EnterMeeting';
import Spinner from '../../common/Spinner';
import { isIpad, loadScript, isMobile } from '../../utils/browser-util';

export default class OffWallStream extends PureComponent {
  state = {
    streamUnavailable: false,
    playerState: null,
    showEnterMeeting: null,
    customStyle: {},
    fullscreen: false,
  };

  _videoRef = React.createRef();

  componentDidMount() {
    this._debouncedHandleResize = debounce(250, this._handleResize);
    window.addEventListener('resize', this._debouncedHandleResize);

    const params = new URLSearchParams(window.location.search);
    const isOffWallTestClient = params.get('isOffWallTestClient') === 'true';

    console.log('Loading Amazon IVS player');

    loadScript('https://player.live-video.net/1.20.0/amazon-ivs-player.min.js', () => {
      if (isOffWallTestClient) {
        this._onEnterMeeting();
      } else if (window.IVSPlayer.isPlayerSupported) {
        this.setState({ showEnterMeeting: true });
      } else {
        swal({
          title: 'Browser not supported',
          text: 'Your browser is not compatible. Please use a different browser or call the help desk at 866-864-2865.',
          className: isIpad ? 'swal-break-ipad' : 'swal-break',
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._debouncedHandleResize);
  }

  _handleResize = () => {
    const { showHtmlWidget, htmlWidget } = this.props;
    const streamContainer = document.getElementById('stream-container');

    let customStyle = {};
    if (showHtmlWidget) {
      let widgetWidth = htmlWidget.width;
      if (htmlWidget.widthUnits === '%' && streamContainer) {
        widgetWidth = (streamContainer.offsetWidth * htmlWidget.width) / 100;
      }

      if (htmlWidget.pinTo.indexOf('-left') !== -1) {
        customStyle = {
          paddingLeft: widgetWidth + htmlWidget.spacing.left,
        };
      } else {
        customStyle = {
          paddingRight: widgetWidth + htmlWidget.spacing.right,
        };
      }
    }
    this.setState({ customStyle });
  };

  _stream = () => {
    this.videoElement = document.getElementById('off-wall-player');
    this.player = window.IVSPlayer.create();
    this.player.attachHTMLVideoElement(this.videoElement);

    const { PlayerState, PlayerEventType } = window.IVSPlayer;
    const { READY, PLAYING, ENDED } = PlayerState;
    const { ERROR } = PlayerEventType;

    this.player.addEventListener(READY, () => {
      this.setState({ playerState: READY });
    });

    this.player.addEventListener(PLAYING, () => {
      this.setState({ playerState: PLAYING, streamUnavailable: false });
    });

    this.player.addEventListener(ENDED, () => {
      this.setState({ playerState: ENDED, streamUnavailable: true });
      this.player.delete();
      setTimeout(() => {
        this._stream();
      }, 3000);
    });

    this.player.addEventListener(ERROR, (e) => {
      console.log('Player error:', e);
      this.setState({ playerState: ERROR, streamUnavailable: true });
      this.player.delete();
      setTimeout(() => {
        this._stream();
      }, 3000);
    });
    this.player.load(this.props.url);
    this.player.play();
    this._handleResize();
  };

  _onEnterMeeting = () => {
    this._stream();
    this.setState({ showEnterMeeting: false });
    this.props.onEnterMeeting();
  };

  render() {
    const { showEnterMeeting, streamUnavailable, customStyle, playerState } = this.state;
    const { PLAYING } = window.IVSPlayer ? window.IVSPlayer.PlayerState : {};

    return (
      <div className='off-wall-stream'>
        <div className='off-wall-stream-container' style={isMobile() ? { ...customStyle, padding: 5 } : customStyle}>
          {showEnterMeeting && (
            <div className='message' style={customStyle}>
              <EnterMeeting onClick={this._onEnterMeeting} />
            </div>
          )}
          <div className='video-container' style={{ position: 'relative', width: '100%', height: '100%' }}>
            <video
              ref={this._videoRef}
              id='off-wall-player'
              autoPlay
              muted={false}
              controls={false}
              style={{ width: '100%', height: '100%' }}
              onContextMenu={(e) => e.preventDefault()}
              playsInline
            />
          </div>
          {!showEnterMeeting && !streamUnavailable && playerState !== PLAYING && (
            <div style={{ position: 'absolute' }}>
              <Spinner />
            </div>
          )}
          {streamUnavailable && (
            <div className='message'>
              <OffWallStreamUnavailable />
            </div>
          )}
        </div>
      </div>
    );
  }
}
